<template>
	<div class="d-flex flex-column">
		<v-subheader v-if="!consecutive" class="float-left ml-8 mr-auto py-1">{{ message.author.fullname }}</v-subheader>
		<v-sheet
			tag="div"
			color="blue"
			class="float-left ml-5 mr-auto mb-3 py-2 px-5"
			:max-width="$vuetify.breakpoint.mdAndUp ? '60vh' : '80vh'"
			rounded="xl"
			outlined
		>
			<p class="body-1 mb-0" style="word-break: break-word; white-space: pre-line">{{ message.content }}</p>
			<span class="subtitle-2 float-left">{{ humanTime(message.timestamp) }}</span>
		</v-sheet>
	</div>
</template>

<script>
export default {
	name: 'ChatMessageIncoming',
	props: {
		message: {
			type: Object,
			required: true
		},
		chat: {
			type: Object,
			required: true
		}
	},
	computed: {
		consecutive() {
			let index = this.chat.messages.indexOf(this.message)
			if (index < 1) {
				return false
			} else {
				return this.chat.messages[index - 1].incoming
			}
		}
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment
				.utc(timestamp)
				.locale(this.$i18n.locale)
				.format('dddd, YYYY-MM-DD HH:mm:ss')
		}
	}
}
</script>
